import { Institute } from "../institute";
import { Tag } from "../tag";
import { User } from "../users/user";
import { ProtocolUpdate } from "./protocol-update";
import { ProtocolError } from "./protocol-error";
import { ISharing } from "../protocol/isharing";
import { ProtocolAttachment } from "../../entities/protocol-attachment";
import { IContent } from "../icontent";
import { ProtocolWarning } from "../../modules/protocols/classes/protocol-warning";
import { ProtocolApproval } from "../../modules/protocols/entities/protocol-approval";

export class Protocol implements IContent {
	id: number;
	label: string;
	institute: Institute;
	tags: Tag[];
	status: number;
	authors: User[];
	approvers: User[];
	managers: User[];
	expiry: number;
	updates: ProtocolUpdate[];
	errors: ProtocolError[];
	codification: string;
	sharing: ISharing;
	last_change: string;
	attachments: ProtocolAttachment[];
	warnings: ProtocolWarning[];
	approvals: ProtocolApproval[];
	current_status: string;
}

export enum ProtocolStatus {
	draft = 0, //offline
	published = 1, //online
	archived = 2,
}

export enum EProtocolCurrentStatus {
	draft = "draft",
	published = "published",
	archived = "archived",
	medical = "medical",
	quality = "quality",
}
